/**
 * Global configuration
 */
const config = {
	PROXY_URL: '/proxy.php',
	API_URL: 'https://services.mypass.technology',
	API_PATH_PRODUCTION: '/MPLumenAPI/v1',
	API_PATH_DEVELOPMENT: '/MPLumenAPI.test/reservation',
	API_PLATFORM: "web",
	API_VERSION: "1.0.0",
	API_PORTAL: "marketplace",
  API_TIMEOUT: (15 * 1000),
  company: {
    pos_id: 54,
  },
  currency: {
    symbol: '€',
    name: 'EUR',
    decimalCharacter: '.',
    digitGroupSeparator: ',',
  },
  geo: {
    enableHighAccuracy: true,
    maximumAge: 15000,
    timeout: 12000,
    fallbackLatitude: 41.9028, // Rome (IT) latitude
    fallbackLongitude: 12.4964, // Rome (IT) longitude
  },
  places: {
    // // GOOGLE
    // GOOGLE_MAPS_API_KEY: "AIzaSyBQBJOcjSbj0NTIpZWkhIXClBXMGnjUpZ4",
    // googleMapsPlacesRestrictions: {
    //   country: 'it',
    // },
    // ALGOLIA
    ALGOLIA_APP_ID: 'plZA8RZD3QBN',
    ALGOLIA_API_KEY: 'ec62c7ef5e3df126ba613af8341ebf4f',
    restrictions: {
      countries: ['it'],
    }
  },
  images: {
    maxWidth: 800,
    maxHeight: 800,
    format: 'JPEG', // JPEG / PNG / WEBP
    quality: 75, // [0-100]
    rotationAngleDegrees: 0, // [0-360]
  },
	languages: [ // languages we want to translate the ui
		{
			id: 'en',
      flag: '🇺🇸',
			name: 'English',
		},
		{
			id: 'it',
      flag: '🇮🇹',
			name: 'Italiano',
		},
/*
		{
			id: 'ar',
      flag: '🇦🇷',
      name: 'العربية',
		}
*/
  ],
  roles: [ "marketplace", "marketplace-user", "marketplace-only-reservations" ], // roles accepted for login
  search: {
    debounceMilliseconds: 500,
  },
  fallbackUIDateFormat: 'DD/MM/YYYY',
  fallbackDBDateFormat: 'YYYY-MM-DD',
  fallbackLanguage: 'it',
  fallbackUserTheme: {
    settings: {
      layout: {
        style: "layout1",
        config: {
          scroll: "content",
          navbar: {
            display: true,
            folded: true,
            position: "left"
          },
          toolbar: {
            display: true,
            style: "fixed",
            position: "below"
          },
          footer: {
            display: true,
            style: "fixed",
            position: "below"
          },
          mode: "fullwidth"
        }
      },
      customScrollbars: true,
      theme: {
        main: "default",
        navbar: "default",
        toolbar: "default",
        footer: "defaultDark"
      }
    },
    shortcuts: [
      "calendar",
      "mail",
      "contacts"
    ],
  },
};

export default config;