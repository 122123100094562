import { authRoles } from 'app/auth';
import i18n from 'i18n';
const t = (...args) => i18n.t(...args);

const navigationConfig = [
	{
		id: 'applications',
		// title: 'Applications',
		// translate: 'APPLICATIONS',
		// COMPANYINFO: "Company Info",
		// INFO: "Info",
		// DETAILS: "Details",
		// USERS: "Users",
		type: 'group',
		icon: 'apps',
		children: [
			/*{
				id: 'only-guest-navigation-item',
				title: 'Nav item only for Guest',
				type: 'item',
				auth: authRoles.onlyGuest, // ['guest']
				url: '/guest', //'/auth/guest-role',
				icon: 'person', //'verified_user'
		  },*/
      {
				id: 'company',
				title: 'Company',
				translate: t('COMPANY'),
				type: 'collapse',
				auth: authRoles.user,
				icon: 'business',
				url: '/sections/company/info',
				children: [
					{
						id: 'company-info',
						title: 'Info',
						translate: t('INFO'),
						type: 'item',
						auth: authRoles.user,
						icon: 'info',
						url: '/sections/company/info'
					},
          {
            id: 'company-shops',
            title: 'Shops',
            translate: t('SHOPS'),
            type: 'item',
						auth: authRoles.user,
            icon: 'work',
            url: '/sections/company/shops'
          },
/*
					{
						id: 'company-details',
						title: 'Details',
						translate: t('DETAILS'),
						type: 'item',
						auth: authRoles.user,
						icon: 'work',
						url: '/sections/company/details'
					},
					{
						id: 'company-users',
						title: 'Users',
						translate: t('USERS'),
						type: 'item',
						auth: authRoles.user,
						icon: 'account_circle',
						url: '/sections/company/users'
					}
*/
			  ]
			},
			{
				id: 'associate-users',
				title: 'Users',
				translate: t('USERS'),
				type: 'item',
				auth: authRoles.user,
				icon: 'account_circle',
				url: '/sections/associateusers'
			},
    	{
				id: 'articles',
				title: 'Articles',
				translate: t('ARTICLES'),
				type: 'item',
				auth: authRoles.user,
				icon: 'shopping_cart',
				url: '/sections/articles',
			},
			{
				id: 'reservations',
				title: 'Reservations',
				translate: t('RESERVATIONS'),
				type: 'item',
				auth: authRoles.userOnlyReservations,
				icon: 'alarm_on',
				url: '/sections/reservations',
			},
      {
        id: 'accounting',
        title: 'Accounting',
        translate: t('ACCOUNTING'),
        type: 'item',
				auth: authRoles.user,
        icon: 'euro_symbol',
        url: '/sections/accounting/balance',
      },
/*
			{
				id: 'accounting',
				title: 'Accounting',
				translate: t('ACCOUNTING'),
				type: 'collapse',
				auth: authRoles.user,
				icon: 'euro_symbol',
				url: '/sections/accounting/balance',
				children: [
					{
						id: 'accounting-balance',
						title: 'Balance',
						translate: t('BALANCE'),
						type: 'item',
						auth: authRoles.user,
						icon: 'account_balance_wallet',
						url: '/sections/accounting/balance'
					},
					{
						id: 'accounting-invoices',
						title: 'Invoices',
						translate: t('INVOICES'),
						type: 'item',
						auth: authRoles.user,
						icon: 'receipt',
						url: '/sections/accounting/invoices'
					},
					{
						id: 'accounting-payments',
						title: 'Payments',
						translate: t('PAYMENTS'),
						type: 'item',
						auth: authRoles.user,
						icon: 'payment',
						url: '/sections/accounting/payments'
					}
				]
			},			
*/
			// {
			// 	id: 'todo',
			// 	title: 'To-Do',
			// 	translate: 'TODO',
			// 	type: 'item',
			// auth: authRoles.user,
			// 	icon: 'check_box',
			// 	url: '/apps/todo',
			// 	badge: {
			// 		title: 3,
			// 		bg: 'rgb(255, 111, 0)',
			// 		fg: '#FFFFFF'
			// 	}
			// },
			// {
			// 	id: 'contacts',
			// 	title: 'Contacts',
			// 	translate: 'CONTACTS',
			// 	type: 'item',
			//  auth: authRoles.user,
			// 	icon: 'account_box',
			// 	url: '/apps/contacts/all'
			// },
		]
	},
	// {
	// 	id: 'pages',
	// 	title: 'Pages',
	// 	type: 'group',
  // auth: authRoles.user,
	// 	icon: 'pages',
	// 	children: [
	// 		{
	// 			id: 'profile',
	// 			title: 'Profile',
	// 			type: 'item',
  //			auth: authRoles.user,
	// 			icon: 'person',
	// 			url: '/pages/profile'
	// 		},
	// 	]
	// },

];

export default navigationConfig;
