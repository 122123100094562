import ArticlesConfig from './articles/ArticlesConfig.js';
import CompanyConfig from './company/CompanyConfig.js';
import AssociateusersConfig from './associateusers/AssociateusersConfig.js';
import ReservationsConfig from './reservations/ReservationsConfig.js';
import AccountingConfig from './accounting/AccountingConfig.js';

const sectionsConfigs = [
	ArticlesConfig,
	CompanyConfig,
	AssociateusersConfig,
	ReservationsConfig,
	AccountingConfig,
];

export default sectionsConfigs;