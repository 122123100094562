import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
//import jwtDecode from 'jwt-decode';
import NetworkService from 'app/services/networkService';
import config from 'config';
import i18n from 'i18n';
const t = (...args) => i18n.t(...args);

/* eslint-disable camelcase */

class JwtMypassService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
          //console.warn('error detected in interceptor:', err, err.response.status, err.response && err.response.data ? err.response.data.message : 'undefined');
					if (!err.response) { // network error
						return this.emit('onServiceError', err.message);
					}
					switch (err.response.status) {
						case 400: // directly handle in fetch
							reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : err.response.status);
							break;
						case 401:
							if (err.config && !err.config.__isRetryRequest) {
								// if you ever get an unauthorized response, logout the user
								this.emit('onAutoLogout', t('Invalid access token'));
								this.setSession(null);
								break;
							}
							// do not break, continue
							// eslint-disable-next-line no-fallthrough
						case 403:
							this.emit('onAutoLogout', t('Unhautorized. Please login.'));
							this.setSession(null);
							break;
						case 404:
							this.emit('onServiceError', t('Sorry, this function has not yet been implemented on server'));
							break;
						default:
							this.emit('onServiceError', /*err.message +*/ (err.response && err.response.data ? err.response.data.message : t('Undefined error')/* should not happen */));
							break;
					}
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');
			return;
		}

		const valid = this.isAuthTokenValid(access_token);
		if (valid === null) {
  		this.setSession(null);
			this.emit('onAutoLogout', 'invalid access token format');
	  } else
		if (valid) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/register', data).then(response => {
				if (response.data.user) {
					this.setSession(response.data.access_token);
					resolve(response.data.user);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	signInWithEmailAndPassword = (email, password) => {
		return new Promise((resolve, reject) => {
			const endpoint = 'users/login';
			const method = 'POST';
			const data = { email, password };
			
			//console.log("data.password:", data.password);
			//data.password = sha1(data.password); // hash password
			//console.log("sha1 data.password:", data.password);

			NetworkService.fetch(endpoint, method, data)
				.then(response => {
					if (response.data) {
						if (!response.data.access_token) {
							return reject({"email":"no access token from server"});
						}

						console.log("response.data.roles:", response.data.roles);
						const role = this.mapServerRoleToInternalRole(response.data.roles); // internal role
						console.log("mapServerRoleToInternalRole role:", role);
						const roles = response.data.roles && response.data.roles.filter((role) => config.roles.includes(role.role));
						console.log("filtered on role roles:", roles);
						if (!roles || !roles.length) {
							// not any config.roles found inside allowed roles
							return reject({ "email": "this user is not allowed here" });
						}
						
						this.setSession(response.data.access_token);

            const user = {
              "auth": true,
							"uuid": response.data.user_id,
							"from": "jwtMypass",
              "role": role, // internal role
              "roles": roles, // [ { merchant_id: 1, merchant_name: "M1", event_id: 1, event_name: "E1", role: "R1" }, ... ]
              "data": {
                "displayName": response.data.email.replace(/@.*/, ''),
                "photoURL": response.data.all.photo ? response.data.all.photo : "assets/images/avatars/_unknown.png",
                "email": response.data.email,
                ...config.fallbackUserTheme,
              }
            };
						resolve(user);
					} else {
						reject(response.error);
					}
				})
				.catch(error => {
					// errors from server is localized text! :-( TODO: should add cases for all languages, or use currentLanguage
					switch (error) {
						case 'The email must be a valid email address.':
						case 'La mail non è valida.':
							reject({"email": error});
							break;
						case 'Invalid email or password.':
						case 'Email o password non validi.':
							reject({"password": error});
							break;
						default:
							reject({"email": error});
							break;
					}
				});
			;
		})
  };

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			const endpoint = 'users/signinWithToken';
			const method = 'GET';
			const data = {};

			NetworkService.fetch(endpoint, method, data)
				.then(response => {
					if (response.data) {
						if (!response.data.access_token) {
							return reject({"email":"no access token from server"});
						}
						//console.log("response.data.user.roles:", response.data.user.roles);
						const role = this.mapServerRoleToInternalRole(response.data.user.roles); // internal role
						//console.log("mapServerRoleToInternalRole role:", role);
						const roles = response.data.user.roles && response.data.user.roles.filter((role) => config.roles.includes(role.role));
						//console.log("filtered on role roles:", roles);
            if (!roles || !roles.length) { // not any config.roles found inside allowed roles
              return reject({ "email": "this user is not allowed here" });
            }
						
						this.setSession(response.data.access_token);

						const user = {
              "auth": true,
							"uuid": response.data.user.id_ut,
							"from": "jwtMypass",
							"role": role, // internal role
							"roles": roles, // these are shops! (ex.: [ { merchant_id: 1, merchant_name: "M1", event_id: 1, event_name: "E1", role: "R1" }, ... ])
							"data": {
								"displayName": response.data.user.name + " " + response.data.user.surname,
								"photoURL": response.data.user.photo ? response.data.user.photo : "assets/images/avatars/_unknown.png",
								"email": response.data.user.email,
								...config.fallbackUserTheme,
							}
						};
						resolve(user);
					} else {
						this.logout(); // force logout in case of no response data - possibly token expiration (needed with lumen 7.0)
						reject(response.error);
					}
				})
				.catch(error => {
					this.logout();
					reject('Failed to login with token: ' + error);
				});
		});
	};

/*
	mapServerRoleToInternalRole = (roles, currentRole) => {
    switch (roles[currentRole].role) {
			case "backoffice":
				return "admin";
			case "marketplace":
				return "staff";
			default:
        //return "guest";
        return "user";
		}
	}
*/

	mapServerRoleToInternalRole = (roles) => {
		if (roles && roles.some(e => e.role === 'backoffice')) return 'admin';
		if (roles && roles.some(e => e.role === 'marketplace')) return 'staff';
		if (roles && roles.some(e => e.role === 'marketplace-only-reservations')) return 'user-only-reservations';
		return 'user';
	}

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	logout = () => {
		this.setSession(null);
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			console.warn('JWT - access token is null!');
			return false;
		}
    return true;
/*

    TODO: WARNING: this code is commented because currently MyPass server tokens are not really JWT...

		try {
 			const decoded = jwtDecode(access_token, { header: true });
			const currentTime = Date.now() / 1000;
			if (decoded.exp < currentTime) {
				console.warn('JWT - access token is expired!');
				return false;
			}
			return true;
		} catch (e) {
			console.warn('JWT - error decoding access token ' + access_token + ':', e);
		 	return null;
		}
*/
	};

	getAccessToken = () => {
		return localStorage.getItem('jwt_access_token');
  };
  
  sendResetPasswordEmail = (email) => {
    return new Promise((resolve, reject) => {
      const endpoint = 'users/recoverPassword';
      const method = 'POST';
      const data = { email };

      NetworkService.fetch(endpoint, method, data)
        .then(response => {
          if (response.data) {
            resolve(true);
          } else {
            reject(response.error);
          }
        })
        .catch(error => {
          reject(error);
        });
      ;
    })
  };

}

/*
function sha1(str) {
  //  discuss at: http://phpjs.org/functions/sha1/
  // original by: Webtoolkit.info (http://www.webtoolkit.info/)
  // improved by: Michael White (http://getsprink.com)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  //    input by: Brett Zamir (http://brett-zamir.me)
  //  depends on: utf8_encode
  //   example 1: sha1('Kevin van Zonneveld');
  //   returns 1: '54916d2e62f65b3afa6e192e6a601cdbe5cb5897'

  var rotate_left = function(n, s) {
    var t4 = (n << s) | (n >>> (32 - s));
    return t4;
  };

  var cvt_hex = function(val) {
    var str = '';
    var i;
    var v;

    for (i = 7; i >= 0; i--) {
      v = (val >>> (i * 4)) & 0x0f;
      str += v.toString(16);
    }
    return str;
  };

  var blockstart;
  var i, j;
  var W = new Array(80);
  var H0 = 0x67452301;
  var H1 = 0xEFCDAB89;
  var H2 = 0x98BADCFE;
  var H3 = 0x10325476;
  var H4 = 0xC3D2E1F0;
  var A, B, C, D, E;
  var temp;

  str = this.utf8_encode(str);
  var str_len = str.length;

  var word_array = [];
  for (i = 0; i < str_len - 3; i += 4) {
		// eslint-disable no-mixed-operators
    j = str.charCodeAt(i) << 24 | str.charCodeAt(i + 1) << 16 | str.charCodeAt(i + 2) << 8 | str.charCodeAt(i + 3);
    word_array.push(j);
  }

  switch (str_len % 4) {
    case 0:
      i = 0x080000000;
      break;
    case 1:
      i = str.charCodeAt(str_len - 1) << 24 | 0x0800000;
      break;
    case 2:
      i = str.charCodeAt(str_len - 2) << 24 | str.charCodeAt(str_len - 1) << 16 | 0x08000;
      break;
    case 3:
      i = str.charCodeAt(str_len - 3) << 24 | str.charCodeAt(str_len - 2) << 16 | str.charCodeAt(str_len - 1) <<
        8 | 0x80;
      break;
		default:
			break;
  }

  word_array.push(i);

	// eslint-disable eqeqeq
  while ((word_array.length % 16) != 14) {
    word_array.push(0);
  }

  word_array.push(str_len >>> 29);
  word_array.push((str_len << 3) & 0x0ffffffff);

  for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
    for (i = 0; i < 16; i++) {
      W[i] = word_array[blockstart + i];
    }
    for (i = 16; i <= 79; i++) {
      W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
    }

    A = H0;
    B = H1;
    C = H2;
    D = H3;
    E = H4;

    for (i = 0; i <= 19; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }

    for (i = 20; i <= 39; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }

    for (i = 40; i <= 59; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }

    for (i = 60; i <= 79; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }

    H0 = (H0 + A) & 0x0ffffffff;
    H1 = (H1 + B) & 0x0ffffffff;
    H2 = (H2 + C) & 0x0ffffffff;
    H3 = (H3 + D) & 0x0ffffffff;
    H4 = (H4 + E) & 0x0ffffffff;
  }

  temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
  return temp.toLowerCase();
}
*/

const instance = new JwtMypassService();

export default instance;