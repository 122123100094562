/**
 * Authorization Roles
 */
const authRoles = {
	admin: ['admin'],
	staff: ['admin', 'staff'],
	user: ['admin', 'staff', 'user'],
	userOnlyReservations: ['admin', 'staff', 'user', 'user-only-reservations'],
	onlyGuest: []
};

export default authRoles;
